/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";
import PropTypes from "prop-types";

import Navbar from "./Navbar";
import Footer from "./Footer";

const Layout = ({ children, display, navcolor }) => {
  return (
    <>
      <div className="flex flex-col max-w-8xl mx-auto px-2">
        <Navbar navcolor={navcolor} />
        <main>{children}</main>
      </div>
      <Footer display={display} />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
