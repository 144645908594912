import React from "react";

export const ButtonMenu = ({ display }) => {
  return (
    <div className="relative left-0 right-0 lg:hidden">
      {display === "block" && (
        <div>
          <svg
            className="fixed top-8 right-8 z-50"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 17.9999L18 5.99989M6 5.99989L18 17.9999"
              stroke="#E77A3F"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      )}

      {display === "hidden" && (
        <div className="lg:hidden">
          <svg
            className="fixed top-8 right-8 z-50"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 5.99989H20M4 11.9999H20M4 17.9999H20"
              stroke="#E77A3F"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      )}
    </div>
  );
};
