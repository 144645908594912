import React, { useState } from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import { ButtonMenu } from "../components/Buttons";

const Navlink = ({
  name,
  to,
  navcolor = "text-main",
  display = "hidden",
  setDisplay = "hidden",
}) => {
  return (
    <AnchorLink
      onAnchorLinkClick={() =>
        display === "hidden" ? setDisplay("block") : setDisplay("hidden")
      }
      to={to}
      className={`lg:${navcolor} navbar px-2 lg:px-0 lg:text-center transition ease-in-out delay-50 lg:hover:-translate-y-0.5 lg:hover:scale-105 hover:font-bold duration-300`}
    >
      {name}
    </AnchorLink>
  );
};

const Navbar = ({ navcolor }) => {
  const [display, setDisplay] = useState("hidden");
  return (
    <>
      <div
        onClick={() =>
          display === "hidden" ? setDisplay("block") : setDisplay("hidden")
        }
      >
        <ButtonMenu display={display} />
      </div>
      <div
        className={`${display} backdrop-blur-sm lg:block lg:text-sm xl:text-base z-40 font-semi flex flex-col  fixed top-2 right-2 lg:relative lg:-top-1 lg:right-0 bg-white lg:bg-transparent  w-80 lg:w-full gap-4 p-4  lg:px-2 xl:px-0 lg:pb-2 lg:shadow-none 2xl:rounded-lg `}
      >
        <div
          className={` ${navcolor} pt-6 pb-2 lg:py-4 flex flex-col gap-5 lg:flex-row lg:justify-between`}
        >
          <Navlink
            name="Bienvenidos"
            display={display}
            setDisplay={setDisplay}
            to="/"
          />
          <Navlink
            name="Visita Nexpa"
            display={display}
            setDisplay={setDisplay}
            to="/#about"
          />
          <Navlink
            display={display}
            setDisplay={setDisplay}
            name="Villas Cheyos"
            to="/services"
          />
          <Navlink
            display={display}
            setDisplay={setDisplay}
            name="Nuestras Cabañas"
            to="/card"
          />
          <Navlink
            display={display}
            setDisplay={setDisplay}
            name="Donde estamos"
            to="/contact"
          />
        </div>
      </div>
      <div className="h-16 mb-1 z-0 lg:h-8"></div>
    </>
  );
};

export default Navbar;
