import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { IconAirbnb, IconFacebook } from "../atoms/Icons";

const Footer = ({ display = "flex" }) => {
  return (
    <div
      className={`${display} flex-col flex-grow-1 items-center justify-evenly gap-1 py-1 h-52 bg-primary w-full mt-16`}
    >
      <div className="flex items-center gap-8 p-8 lg:scale-125">
        <a href="https://www.facebook.com/cheyo.villas.5">
          <IconFacebook fill={"#FFF"} />
        </a>
        <a href="https://www.airbnb.mx/rooms/33832720?source_impression_id=p3_1645971997_f9WPZNlf36FaGjlJ">
          <IconAirbnb fill={"#FFF"} />
        </a>
      </div>
      <div className="flex items-center -mb-6">
        <p className="my-auto text-secondary font-semibold tracking-wider ">
          Surf & WebDesign{" "}
        </p>
        <a href="https://funksion.site">
          <StaticImage
            height={16}
            src="../images/logoF.png"
            alt="funksion logo"
            className="my-auto mt-1 ml-2"
          />
        </a>
      </div>
    </div>
  );
};

export default Footer;
